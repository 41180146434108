import styled from "styled-components";

export const StyledCartItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding-top: 24px;
  display: flex;
  align-items: center;
  height: auto;

  h3 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;
    color: #000;
  }

  p {
    margin-bottom: 0;
    /* margin-left: 50px; */
  }

  button {
    margin-left: 50px;
  }

  @media (min-width: 601px) {
    .mobile-price-list {
      display: none;
    }
  }

  @media (max-width: 600px) {
    .m-w-100 {
      width: 100%;
    }

    .mobile-cart {
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
    }

    #mobile-cart-item-div {
      padding-top: 24px;
    }

    .mobile-hide {
      display: none;
    }

    .cart-close-btn {
      margin-left: 105px;
      margin-top: -115px;
    }

    .cart-list-img {
      min-width: 120px;
      width: 120px;
      height: 120px;
    }

    .prod_details_mobile_div {
      padding-top: 0;
    }
  }
`;
