import styled from "styled-components";

export const StyledStoreCart = styled.div`
  font-family: "IBM Plex Sans";

  max-width: 1176px;
  margin: 0 auto;
  padding: 48px 32px 75px;

  .mobile-visible {
    display: none;
  }

  .shipping-btn-link {
    width: 100%;
    text-decoration: none;

    button {
      &:hover {
        background: #000;
      }

      &:disabled {
        &:hover {
          background: #23303d;
        }
      }
    }
  }

  form {
    label {
      margin-bottom: 15px;
      color: #23303d;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
    }

    select {
      border: 1px solid #dce2e8;
      background: transparent;
      height: 44px;
      width: 100%;
      padding: 10px 18px;
      border-radius: 8px;
      margin-bottom: 40px;
      cursor: pointer;

      color: #4f6c86;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400;

      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;

      background: url("https://graphql-engine-cdn.hasura.io/assets/main-site/arrow_down.svg")
        no-repeat;
      background-position: calc(100% - 0.75rem) center !important;
    }
  }

  @media (max-width: 600px) {
    .mobile-visible {
      display: block;
    }

    .mobile-hide {
      display: none;
    }

    .shipping-btn-link {
      flex: 1;
      width: 100%;
    }

    .shipping-btn {
      margin-left: 0;
      width: 100%;
    }

    .flex-mobile-center {
      justify-content: center;
    }

    .coupon-flex-div {
      flex-direction: column;

      align-items: center;
    }

    .coupon-input {
      input {
        width: 180px;
        font-size: 16px !important;
      }
    }

    .total-div {
      margin-top: 0px;
    }

    .apply-code-btn {
      width: 120px;
    }

    .prod_details_mobile_div {
      h3 {
        white-space: nowrap;
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 7px;
      }

      p {
        white-space: nowrap;
      }
    }
  }
`;
