import React, { useState, useEffect } from "react";

import { getTotalNoOfAddedCartItems } from "../storeHelper";
import { getCartItemsFromLocalStorage } from "../storeHelper";
import { CartItemsList } from "./CartItemsList";
import { EmptyCart } from "./EmptyCart";

const getTotalCartPrice = (cartItemsArr) => {
  if (cartItemsArr && cartItemsArr.length > 0) {
    const totalCartPrice = cartItemsArr.reduce(
      (acc, { product_count, product_price }) => {
        const productPrice = product_price / 100;

        const totalItemPrice = productPrice * product_count;

        return acc + totalItemPrice;
      },
      0
    );

    return totalCartPrice;
  }

  return null;
};

// ******************************* //

export const StoreCart = (props) => {
  const [noOfCartItemsAdded, updateNumberOfCartItems] = useState(
    getTotalNoOfAddedCartItems()
  );

  const [totalCartPrice, updateCartTotalPrice] = useState(0);

  const [cartItems, updateCartItemsStateinComponent] = useState([]);

  // ****************************** //

  useEffect(() => {
    const cartItemsArr = getCartItemsFromLocalStorage();

    const newCartPrice = getTotalCartPrice(cartItemsArr);

    updateCartItemsStateinComponent(cartItemsArr);

    updateCartTotalPrice(newCartPrice);
  }, [noOfCartItemsAdded]);

  // ****************************** //

  const getCartPageContent = () => {
    if (cartItems && cartItems.length > 0) {
      return (
        <CartItemsList
          cartItems={cartItems}
          totalCartPrice={totalCartPrice}
          updateNumberOfCartItemsInStoreComponent={updateNumberOfCartItems}
        />
      );
    }

    return <EmptyCart />;
  };

  return getCartPageContent();
};
