import React from "react";
import { css } from "styled-components";
import { Link } from "gatsby";

import { Flex, Text } from "../../../globals/UIKit";
import { STORE_PAGE_URL } from "../../../utils/constants";
import { scrollToTop } from "../../../utils/helpers";

export const EmptyCart = () => (
  <Flex
    flexDirection="column"
    width="100%"
    pt="100px"
    minHeight="calc(100vh - 263px)"
    css={css`
      @media (max-width: 600px) {
        padding: 50px 30px;

        p {
          font-size: 24px;
          margin-bottom: 30px;
        }

        .empty-cart-p {
          font-size: 30px;
        }
      }
    `}
  >
    <Text
      fontSize="32px"
      fontWeight={600}
      color="#545F68"
      lineHeight="1.5"
      mb="15px"
      textAlign="center"
      className="empty-cart-p"
    >
      Your shopping cart is empty!
    </Text>
    <Link to={STORE_PAGE_URL} onClick={scrollToTop}>
      <Flex
        as="button"
        bg="#23303D"
        fontSize="14px"
        fontWeight={500}
        height="48px"
        width="170px"
        color="#F4FBFF"
        cursor="pointer"
        outline="none"
        border="none"
        borderRadius="4px"
        justifyContent="center"
      >
        Shop Items
      </Flex>
    </Link>
  </Flex>
);
