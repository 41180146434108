import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

import { Image, Flex, Text, Box } from "../../../globals/UIKit";
import { Icon } from "../../../globals/icons";
import {
  removeCartItem,
  getTotalNoOfAddedCartItems,
  getInitialStateForNoOfItemsToAdd,
  updateNoOfItemsInCart,
} from "../storeHelper";
import { scrollToTop } from "../../../utils/helpers";
import { StyledCartItem } from "../styles/StyledCartItem";
import { ProductCount } from "./ProductCount";

export const CartListItem = ({
  cartItemData,
  updateNumberOfCartItemsInStoreComponent,
  isTableHeadingActive,
}) => {
  const [noOfItemsToAdd, updateNoOfItemsToAddInComponentState] = useState(
    getInitialStateForNoOfItemsToAdd(cartItemData?.price_id)
  );

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Step 2 - Update Storage for no of items to add.
      updateNoOfItemsInCart(cartItemData?.price_id, noOfItemsToAdd);

      const getUpdateNoOfCartItemsAdded = getTotalNoOfAddedCartItems();

      updateNumberOfCartItemsInStoreComponent(getUpdateNoOfCartItemsAdded);
    }
  }, [noOfItemsToAdd]);

  const handleNoOfCartItems = (noOfCartItems) => {
    if (noOfCartItems) {
      // STEP 1 - Updating Local Component State
      return updateNoOfItemsToAddInComponentState(noOfCartItems);
    }

    return null;
  };

  const productName = cartItemData?.product_name;

  const priceValue = cartItemData?.product_price / 100;

  const subTotalValue = (
    Math.round(priceValue * noOfItemsToAdd * 100) / 100
  ).toFixed(2);

  const imgSrc = cartItemData?.product_img;

  const handleRemoveCartItem = (event) => {
    removeCartItem(cartItemData?.price_id);

    updateNumberOfCartItemsInStoreComponent(getTotalNoOfAddedCartItems());

    if (event) {
      event.preventDefault();

      return event.stopPropagation();
    }
  };

  const getCartTableHeading = () => {
    if (!isTableHeadingActive) {
      return null;
    }

    return (
      <Flex width="100%" mb="16px">
        <Text
          fontSize="16px"
          lineHeight="1.5"
          fontWeight={500}
          color="#4F6C86"
          width="36%"
          whitespace="nowrap"
        >
          Item
        </Text>
        <Text
          fontSize="16px"
          lineHeight="1.5"
          fontWeight={500}
          color="#4F6C86"
          width="21%"
          className="mobile-hide"
        >
          Price
        </Text>
        <Text
          fontSize="16px"
          lineHeight="1.5"
          fontWeight={500}
          color="#4F6C86"
          width="24%"
          className="mobile-hide"
        >
          Quantity
        </Text>
        <Text
          fontSize="16px"
          lineHeight="1.5"
          fontWeight={500}
          color="#4F6C86"
          width="19%"
          className="mobile-hide"
        >
          Total
        </Text>
      </Flex>
    );
  };

  // ****************************** //

  return (
    <StyledCartItem>
      {getCartTableHeading()}
      <Flex
        width="100%"
        justifyContent="space-between"
        id="mobile-cart-item-div"
        borderTop="1px solid #dce2e8"
        pt="16px"
      >
        <Flex width="36%" className="mobile-cart">
          <Link
            to={`/product/${cartItemData?.product_url}`}
            onClick={scrollToTop}
          >
            <Flex position="relative" className="m-w-100">
              <Image
                src={imgSrc}
                width="80px"
                height="80px"
                alt={productName}
                mr="16px"
                borderRadius="12px"
                className="cart-list-img"
              />
              <Flex
                role="button"
                onClick={(e) => handleRemoveCartItem(e)}
                width="20px"
                height="20px"
                borderRadius="50%"
                justifyContent="center"
                boxShadow="0px 2px 6px 0px #23303D00033"
                position="absolute"
                background="#23303D"
                ml="65px"
                mt="-75px"
                className="cart-close-btn"
              >
                <Icon
                  variant="cross"
                  color="white"
                  size="xs"
                  minWidth="8px"
                  maxWidth="8px"
                  minHeight="8px"
                  maxHeight="8px"
                />
              </Flex>
              <Box className="prod_details_mobile_div mobile-hide">
                <Text
                  color="#23303D"
                  fontSize="16px"
                  lineHeight="1.5"
                  fontWeight={700}
                  pr="12px"
                >
                  {productName}
                </Text>
                {/* <Text fontSize="14px" lineHeight="1.5" fontWeight={500} color="#4F6C86">
                  {`Size - ${sizeId}`}
                </Text> */}
                <Text
                  fontSize="16px"
                  lineHeight="1.5"
                  fontWeight={700}
                  color="#23303D"
                  width="21%"
                  className="mobile-visible"
                >
                  {`${priceValue} USD`}
                </Text>
                <Text
                  fontSize="14px"
                  lineHeight="1.5"
                  fontWeight={500}
                  color="#4F6C86"
                  width="20%"
                  className="mobile-visible"
                >
                  Quantity - 1
                </Text>
              </Box>
            </Flex>
          </Link>
          <Flex
            className="mobile-price-list"
            flexDirection="column"
            alignItems="flex-end"
          >
            <Text
              fontSize="16px"
              lineHeight="1.5"
              fontWeight={700}
              color="#23303D;"
              textAlign="end"
              pb="8px"
            >
              {`${priceValue} USD`}
            </Text>
            <Text
              color="#23303D"
              fontSize="16px"
              lineHeight="1.5"
              fontWeight={700}
              pb="16px"
              textAlign="end"
            >
              {productName}
            </Text>
            <Box>
              <ProductCount
                noOfItemsToAdd={noOfItemsToAdd}
                handleNoOfCartItems={handleNoOfCartItems}
              />
            </Box>
          </Flex>
        </Flex>
        {/* Price */}
        <Text
          color="#23303D"
          fontSize="16px"
          width="21%"
          fontWeight={700}
          className="mobile-hide"
        >{`${priceValue} USD`}</Text>
        <Box width="24%" className="mobile-hide">
          <ProductCount
            noOfItemsToAdd={noOfItemsToAdd}
            handleNoOfCartItems={handleNoOfCartItems}
          />
        </Box>
        <Text
          color="#23303D"
          fontWeight={700}
          fontSize="16px"
          width="19%"
          textAlign="left"
          className="mobile-hide"
        >{`${subTotalValue} USD`}</Text>
      </Flex>
    </StyledCartItem>
  );
};
