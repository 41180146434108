import React from "react";

import { StoreCart } from "../components/store/CartPage";
import { OG_IMAGE } from "../utils/constants";
import { Box } from "../globals/UIKit";
import Layout from "../components/store/Layout";
import SEO from "../components/seo";
import CopyWriterWithSidebar from "../components/common/CopyWriterWithSidebar";
import { ContactUsBox } from "../components/store/StoreListing";

const canonicalUrl = "https://store.hasura.io/cart/";

const Cart = (props) => {
  return (
    <Layout location={props.location}>
      <Box fontFamily="IBM Plex Sans">
        <SEO
          title="Shopping Cart | Hasura Swag Store "
          description="Our favourite Hasura designs now in merch you can get"
          meta={OG_IMAGE}
          canonicalLink={canonicalUrl}
        />
        <StoreCart {...props} />
        <ContactUsBox mb="50px" />
        <CopyWriterWithSidebar location={props.location} />
      </Box>
    </Layout>
  );
};

export default Cart;
