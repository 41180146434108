import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Form } from "informed";
import Select from "react-select";
import countryList from "react-select-country-list";

import { RightTickIcon } from "./RightTickIcon";
import { STORE_CHECKOUT_URL } from "../../../utils/constants";
import { VALIDATE_COUPON_AND_GET_VALUE } from "../../../graphql/queries";
import { Flex, Box, Text, Heading, Image } from "../../../globals/UIKit";
import { Link } from "gatsby";
import { COLORS } from "../../../globals/designSystem";
import { Icon } from "../../../globals/icons";
import { NetworkLoader } from "../../NetworkLoader";
import { CartListItem } from "./CartListItem";
import { StyledStoreCart } from "../styles/StyledStoreCart";
import { STORE_PAGE_URL } from "../../../utils/constants";
import { BackButton } from "../ProductDetails/BackButton";
// import { scrollToTop } from "../../../utils/helpers";
import { websiteApiUrl } from "../../../endpoints";
import axios from "axios";

// /Proxy is configured in gatsby-config.js to route this to the local node server during development to avoid CORS issue
const storeCheckoutUrl = `${websiteApiUrl}/store/create-checkout-session`;

const StyledCouponArrow = styled.div`
  display: flex;
  cursor: pointer;
  svg {
    transition: all 0.3s ease-in-out;
  }
  .rotateImg {
    transform: rotate(180deg);
  }
`;

export const CartItemsList = ({
  cartItems,
  totalCartPrice,
  updateNumberOfCartItemsInStoreComponent,
}) => {
  const [selectedCountry, updateCountryValue] = useState("");

  const [isAPIError, updateAPIErrorState] = useState(false);

  const [showCouponInput, setShowCouponInput] = useState(false);

  const [couponStatus, setCouponStatus] = useState(null);

  const [couponInput, updateCouponInput] = useState("");

  const [couponValue, updateCouponValue] = useState(0);

  const [validateSwagStoreCouponCodeAndGetValue, { loading, error, data }] =
    useLazyQuery(VALIDATE_COUPON_AND_GET_VALUE, {
      onCompleted: (data) => {
        if (data && data.coupons && data.coupons.length > 0) {
          const couponValue = data.coupons[0].coupon_value / 100;

          return updateCouponValue(couponValue);
        } else {
          setCouponStatus("error");
        }
      },
      onError: (err) => {
        console.error(err);
      },
    });

  useEffect(() => {
    if (couponValue === totalCartPrice || couponValue > totalCartPrice) {
      return setCouponStatus("success");
    }

    if (couponValue > 0 && couponValue < totalCartPrice) {
      // console.log(couponValue);

      return setCouponStatus("applied");
    }
  }, [couponValue]);

  useEffect(() => {
    setCouponStatus(null);

    updateCouponInput("");

    return updateCouponValue(0);
  }, [totalCartPrice]);

  const [isNetworkRequestLoading, updateNetworkRequestLoadingStatus] =
    useState(false);

  const isNetworkLoaderActive = isNetworkRequestLoading || loading;

  const handleCouponInput = (event) => {
    updateCouponInput(event.target.value);
  };

  const countries = countryList().getData();

  const handleCountrySelector = (event) => {
    if (event) {
      updateCountryValue(event.value);
    }

    return null;
  };

  const handleCouponSubmit = () => {
    validateSwagStoreCouponCodeAndGetValue({
      variables: {
        coupon: couponInput.trim(),
      },
    });

    // var couponValue = document.getElementById("coupon-code")?.value;
    // if (couponValue === "HASURACODE") {
    //   setCouponStatus("success");
    // }
    // if (couponValue !== "HASURACODE") {
    //   setCouponStatus("error");
    // }
    // if (couponValue === "") {
    //   setCouponStatus("notentered");
    // }
    // console.log(couponStatus);
  };

  const onSubmit = () => {
    if (isNetworkRequestLoading) {
      return null;
    }

    // console.log(cartItems);
    // console.log(selectedCountry);
    const checkoutDetails = {};

    checkoutDetails.cartItems = cartItems;

    checkoutDetails.shippingCountry = selectedCountry;

    // Coupon
    if (couponValue && couponValue > 0 && couponInput) {
      checkoutDetails.coupon = couponInput.trim();
    }
    // checkoutDetails.coupon = getCouponInput();

    updateNetworkRequestLoadingStatus(true);

    axios({
      url: storeCheckoutUrl,
      method: "post",
      data: checkoutDetails,
      responseType: "json",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        updateNetworkRequestLoadingStatus(false);

        // console.log(res.data);
        window.location.replace(res.data);
      })
      .catch((error) => {
        updateNetworkRequestLoadingStatus(false);
        updateAPIErrorState(true);

        console.error(error);
      });
  };

  const getCartItemsList = () => {
    return (
      <Box width="60%" mt="60px" className="cart-row-list">
        {cartItems.map((cartItem, index) => (
          <CartListItem
            key={cartItem?.price_id}
            updateNumberOfCartItemsInStoreComponent={
              updateNumberOfCartItemsInStoreComponent
            }
            isTableHeadingActive={index === 0 ? true : false}
            // v3 - New Props
            cartItemData={cartItem}
          />
        ))}
      </Box>
    );
  };

  const isShippingButtonDisabled =
    selectedCountry === "" || isAPIError ? true : false;

  const buttonText = isNetworkRequestLoading
    ? "Loading..."
    : "Shipping and Payment";

  const getTotalPriceMenu = () => {
    const totalCartPriceValue = (
      Math.round(totalCartPrice * 100) / 100
    ).toFixed(2);

    const finalCartPrice = totalCartPriceValue - couponValue;

    const getCountrySelectorAndPaymentCTA = () => {
      if (couponStatus === "success") {
        return (
          <Link
            to={STORE_CHECKOUT_URL}
            state={{
              totalCartPrice: totalCartPrice,
              cartItems: cartItems,
              coupon: couponInput.trim(),
            }}
            className="shipping-btn-link"
          >
            <Flex
              as="button"
              className="shipping-btn"
              bg="#23303D"
              fontSize="16px"
              fontWeight={500}
              height="44px"
              width="100%"
              color="#F4FBFF"
              cursor="pointer"
              outline="none"
              border="none"
              borderRadius="8px"
              justifyContent="center"
            >
              Continue to Shipping
            </Flex>
          </Link>
        );
      }

      return (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          borderRadius="12px"
          padding="24px"
          bg="#F3F5F7"
          border="1px solid #DCE2E8"
          width="100%"
        >
          <Text
            as="label"
            fontSize="16px"
            fontWeight={700}
            lineHeight="1.5"
            mb="12px"
          >
            Select Country*
          </Text>
          <Box width="100%">
            <Select
              options={countries}
              isSearchable
              maxMenuHeight={200}
              onChange={handleCountrySelector}
              value={countries.find((v) => v.value === selectedCountry)}
            />
          </Box>
          <Flex mb="16px" alignItems="flex-start" mt="40px">
            <RightTickIcon />
            <Text
              as="i"
              fontSize="14px"
              fontWeight="400"
              lineHeight="1.45"
              color="#4F6C86"
              ml="8px"
              mt="-3px"
            >
              Shipping rates are calculated in the next step
            </Text>
          </Flex>
          <Flex className="flex-mobile-center" width="100%">
            <Link
              state={{
                totalCartPrice: totalCartPrice,
                cartItems: cartItems,
              }}
              className="shipping-btn-link"
            >
              <Flex
                onClick={onSubmit}
                as="button"
                className="shipping-btn"
                bg="#23303D"
                fontSize="16px"
                fontWeight={500}
                height="44px"
                width="100%"
                color="#F4FBFF"
                cursor="pointer"
                outline="none"
                border="none"
                borderRadius="8px"
                justifyContent="center"
                opacity={isShippingButtonDisabled ? "0.4" : "1"}
                disabled={isShippingButtonDisabled}
              >
                {buttonText}
              </Flex>
              {isAPIError && (
                <Text mt="12px" color="#e65678" fontSize="14px">
                  *Error: We are not unable to collect payment at this time.
                  Please try again later. For any queries please reach out
                  to&nbsp;
                  <a href="mailto:community@hasura.io">community@hasura.io</a>
                </Text>
              )}
            </Link>
          </Flex>
        </Flex>
      );
    };

    return (
      <Box width="36%" ml="4%" pt="40px" className="price-total-div">
        <Flex
          justifyContent="space-between"
          pt="18px"
          alignItems="flex-start"
          className="coupon-flex-div"
        >
          <Flex
            className="total-div"
            width="100%"
            justifyContent="space-between"
            flexDirection="column"
            borderRadius="12px"
            bg="#F3F5F7"
            border="1px solid #DCE2E8"
            padding="24px"
            mb="24px"
          >
            <Flex
              width="100%"
              justifyContent="space-between"
              onClick={() => {
                setShowCouponInput(!showCouponInput);
              }}
              role="button"
              css={css`
                cursor: pointer;
              `}
            >
              <Text
                color="#23303D"
                fontSize="16px"
                fontWeight="700"
                lineHeight="1.5"
                className="total-desc-p"
              >
                Add coupon code
              </Text>
              <StyledCouponArrow>
                <Icon
                  className={showCouponInput ? "rotateImg" : ""}
                  variant="chevron_down"
                  color="neutral_60"
                  size="md"
                />
              </StyledCouponArrow>
            </Flex>
            {showCouponInput && (
              <Flex
                width="100%"
                paddingTop="20px"
                flexDirection="column"
                css={css`
                  form {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    position: relative;
                    input {
                      width: 100%;
                      height: 44px;
                      outline: none;
                      border: 1px solid ${COLORS.neutral_12};
                      border-radius: 8px;
                      background: #fff;
                      padding: 10px 14px;
                      font-size: 16px;
                      font-weight: 400;
                      color: ${COLORS.neutral_500};
                      &.success {
                        border: 1px solid ${COLORS.green_500};
                      }
                      &.error {
                        border: 1px solid ${COLORS.pink_500};
                      }
                    }
                    ::placeholder {
                      /* Chrome, Firefox, Opera, Safari 10.1+ */
                      color: ${COLORS.neutral_500};
                    }
                    :-ms-input-placeholder {
                      /* Internet Explorer 10-11 */
                      color: ${COLORS.neutral_500};
                    }
                    ::-ms-input-placeholder {
                      /* Microsoft Edge */
                      color: ${COLORS.neutral_500};
                    }
                    button {
                      border: 0;
                      padding: 0;
                      background: transparent;
                      position: absolute;
                      right: 13px;
                      top: 50%;
                      transform: translateY(-50%);
                      display: flex;
                    }
                  }
                `}
              >
                <Form onSubmit={handleCouponSubmit}>
                  <input
                    autoFocus
                    type="text"
                    value={couponInput}
                    onChange={handleCouponInput}
                    name="coupon-code"
                    label=""
                    placeholder="Enter coupon code"
                    id="coupon-code"
                    className={
                      couponStatus === "success"
                        ? "success"
                        : couponStatus === "error"
                        ? "error"
                        : ""
                    }
                  />
                  <button type="submit">
                    <Icon variant="arrow_right" color="neutral_60" size="md" />
                  </button>
                </Form>
                {couponStatus && couponStatus === "applied" && (
                  <Text
                    width="100%"
                    color="#4F6C86"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="1.4"
                    paddingTop="8px"
                    css={css`
                      img {
                        margin-right: 8px;
                      }
                    `}
                  >
                    <img
                      src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1674025996/Swag%20Store/tick-green-circle_mypoxt.svg"
                      alt="tick"
                    />
                    {`Coupon code has been applied successfully!`}
                  </Text>
                )}
                {couponStatus && couponStatus === "success" && (
                  <Text
                    width="100%"
                    color="#4F6C86"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="1.4"
                    paddingTop="8px"
                    css={css`
                      img {
                        margin-right: 8px;
                      }
                    `}
                  >
                    <img
                      src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1674025996/Swag%20Store/tick-green-circle_mypoxt.svg"
                      alt="tick"
                    />
                    {`Yay! You just saved ${totalCartPriceValue} USD!`}
                  </Text>
                )}
                {couponStatus && couponStatus === "error" && (
                  <Text
                    width="100%"
                    color="#4F6C86"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="1.4"
                    paddingTop="8px"
                    display="flex"
                    alignItems="center"
                    css={css`
                      img {
                        margin-right: 8px;
                      }
                    `}
                  >
                    <img
                      src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1674025996/Swag%20Store/cross-red-circle_dfhfef.svg"
                      alt="tick"
                    />
                    This code is incorrect
                  </Text>
                )}
                {couponStatus && couponStatus === "notentered" && (
                  <Text
                    width="100%"
                    color="#4F6C86"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="1.4"
                    paddingTop="12px"
                    // pl="15px"
                  >
                    Please enter coupon
                  </Text>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          pt="18px"
          alignItems="flex-start"
          className="coupon-flex-div"
        >
          <Flex
            className="total-div"
            width="100%"
            justifyContent="space-between"
            height="86px"
            borderRadius="12px"
            bg="#F3F5F7"
            border="1px solid #DCE2E8"
            padding="24px"
            mb="24px"
          >
            <Text
              color="#23303D"
              fontSize="16px"
              fontWeight="700"
              lineHeight="1.5"
              className="total-desc-p"
            >
              Cart total
            </Text>
            <Flex>
              {couponStatus === "success" ||
                (couponStatus === "applied" && (
                  <Text
                    color="#23303D"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="1.5"
                    className="lineThrough"
                    marginRight="12px"
                  >
                    {`${totalCartPriceValue} USD`}
                  </Text>
                ))}
              <Text
                color="#2C64F4"
                fontSize="30px"
                fontWeight="700"
                lineHeight="1.5"
                className="total-p"
              >
                {couponStatus === "success" ? (
                  `0 USD`
                ) : (
                  <>{`${finalCartPrice} USD`}</>
                )}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {/* Country Selector */}
        {getCountrySelectorAndPaymentCTA()}
      </Box>
    );
  };

  return (
    <StyledStoreCart>
      {isNetworkLoaderActive && <NetworkLoader />}
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="flex-start"
        css={css`
          @media (max-width: 1024px) {
            h1 {
              font-size: 24px;
            }
          }
        `}
      >
        <Flex
          width="100%"
          justifyContent="space-between"
          css={css`
            @media (max-width: 620px) {
              #cart-status {
                display: none;
              }
            }
          `}
        >
          <BackButton redirectTo={STORE_PAGE_URL} />
          <Image
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1665578176/Swag%20Store/cart-status_kusuur.svg"
            alt="Cart Status"
            id="cart-status"
            minWidth="222px"
            width="222px"
            height="44px"
          />
        </Flex>
        <Heading
          fontSize="36px"
          fontWeight="700"
          color="#23303D"
          lineHeight="1.25"
          mt="36px"
          mb="0px"
        >
          Shopping cart
        </Heading>
      </Flex>
      <Flex
        width="100%"
        justifyContnet="space-between"
        alignItems="flex-start"
        css={css`
          .lineThrough {
            text-decoration: line-through;
          }
          @media (max-width: 1024px) {
            flex-direction: column;
            justify-content: flex-start;

            .cart-row-list {
              width: 100%;
              margin-top: 24px;
            }

            .price-total-div {
              width: 100%;
              margin-left: 0;
              border-top: 1px solid #dce2e8;
              margin-top: 32px;
              padding-top: 32px;

              .total-desc-p {
                font-size: 16px;
              }
              .total-p {
                font-size: 24px;
              }
            }
          }
        `}
      >
        {getCartItemsList()}
        {getTotalPriceMenu()}
      </Flex>
    </StyledStoreCart>
  );
};
